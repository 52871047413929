@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;1,500&display=swap);
/** @format */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  color: #ffffff;
  background: #030b21;
  font-family: "Poppins", sans-serif;
}
header {
  min-height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}
main {
  margin: 0;
  padding: 0;
}

@media (max-width: 450px) {
  h2 {
    font-size: 30px;
  }
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-md-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.col-md-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.col-md-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

